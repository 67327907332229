import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'

function NotFoundPage({ data }) {
  const slug = `four-oh-four`
  const title = `Page Not Found`
  return (
    <Layout location={slug} pageTitle={title}>
      <div className="hero-outer">
        <div className="overlay">
          <Image
            className="hero-inner"
            fluid={data.file.childImageSharp.fluid}
          />
        </div>
        <div className="title-page-container fade-in">
          <h2
            className="title-page"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </div>
      <div className="container">
        <Link className="font--red" to="/">
          Home
        </Link>
        <div className="font-body">
          <h1 className="quote quote-text quote--full">
            &#34;We&#39;re Sorry...&#34;
          </h1>
          <h2 className="headline">
            The page you&#39;re looking for is not available for one of the
            following reasons:
          </h2>
          <ul>
            <li>The link to this page may be incorrect or out-of-date.</li>
            <li>You may have bookmarked a page that has been moved.</li>
          </ul>
          <h2 className="headline">Here are some helpful links instead:</h2>
          <ul>
            <li>
              Return to
              <Link className="font--red" to="/">
                homepage
              </Link>
            </li>
            <li>
              If you have any questions, you can reach us by email at
              kathryn[at]falmouthacehardware[dot]com
            </li>
            <li>
              You may also reach us by phone during regular business hours at
              <a href="tel:2072106680" className="font--black">
                (207) 210-6680
              </a>
              .
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default NotFoundPage

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
